import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import styled, { ThemeContext, keyframes } from 'styled-components'

import Spacer from 'components/Spacer'

import { addCommas } from 'utils/stringFormatting'
import useDebounce from 'utils/useDebounce'

/* interpolation factors */
const STEPS_PER_INTERVAL = 30

/* tick marks setup */
const priceTickMarks = ['100K', '250K', '550K', '850K', '1.375M', '2.125M']

const defaultTickMarkColors = ['#07BFF1', '#25B7F0', '#19AFEF', '#0FA8EE', '#10A1ED', '#1299EC']

interface IHeightProp {
  marginTop?: number
}
const SliderInputContainer = styled.div<IHeightProp>`
  display: flex;
  width: 100%;
  ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
  margin-bottom: 34px;
`

const SliderFillContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
interface IColorProps {
  color: string
}
interface ILeftProps {
  left: boolean
}
interface IMaxValueProps {
  maxValue: boolean
}
interface IVestibuleWidthProp {
  vestibuleWidth?: string
}
const SliderFillVestibule = styled.div<
  ILeftProps & IMaxValueProps & IColorProps & IVestibuleWidthProp
>`
  height: 12px;
  width: ${props => props.vestibuleWidth || '10%'};

  background: ${props => props.color};
  box-shadow: 12px 12px 24px ${props => props.theme.colors.transparentGrey23Alpha25};
  ${props => `
    border-top-${props.left ? 'left' : 'right'}-radius: 10px;
    border-bottom-${props.left ? 'left' : 'right'}-radius: 10px;
  `}
`

interface IBackgroundProps {
  background: string
}
interface IRoundedProps {
  rounded?: boolean
}
const SliderFill = styled.div<IBackgroundProps & IRoundedProps>`
  flex-grow: 1;
  height: 12px;
  width: 100%;
  background: ${props =>
    props.background.replace(props.theme.colors.blue500, props.theme.colors.teal500)};
  ${props => props.rounded && 'border-radius: 10px;'}
  box-shadow: 12px 12px 24px ${props => props.theme.colors.transparentGrey23Alpha25};
`

/* Metrics Indicators */
interface IPointerProps {
  pointer?: boolean
}
const RelativeContainer = styled.div<IPointerProps>`
  position: relative;
  ${props => props.pointer && 'cursor: pointer;'}
`

const LineContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    margin-top: 25px;
  }
`

const MetricContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const MetricIndicator = styled.div<IColorProps>`
  position: absolute;
  width: 3px;
  height: 10px;
  background-color: ${props => props.color};
  border-radius: 20px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 5px;
    height: 16px;
  }
`

const MetricText = styled.div`
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: ${props => props.theme.colors.neutral400};
  padding-top: 15px;
  font-weight: 800;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: 14px;
    padding-top: 20px;
  }
`

const pulseOuter = keyframes`
  0% {
    transform: scale(0.25);
    opacity: 100%;
  }
  100% {
    transform: scale(1);
    opacity: 0%;
  }
`

const pulseInner = keyframes`
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

const SliderIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const SliderThumbContainer = styled.div`
  outline: none; /* removes outline that appears when dragging the thumb on safari */
`

interface ISizeProps {
  size: number
  desktopSize: number
}
export const SliderThumb = styled.div<ISizeProps>`
  /* inner circle */
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  top: -${props => props.size / 2}px;
  left: -${props => props.size / 2}px;
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : props.theme.colors.blue500)};
  position: absolute;
  z-index: 1;

  animation-duration: 1s;
  animation-name: ${pulseInner};
  animation-iteration-count: infinite;
  animation-direction: alternate;

  &:after {
    /* outer circle */
    content: '';
    width: ${props => props.size * 2}px;
    height: ${props => props.size * 2}px;
    top: -${props => props.size / 2}px;
    left: -${props => props.size / 2}px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.transparentBlue4Alpha65};
    position: absolute;

    animation-duration: 2s;
    animation-name: ${pulseOuter};
    animation-iteration-count: infinite;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    /* inner circle */
    width: ${props => props.desktopSize}px;
    height: ${props => props.desktopSize}px;
    top: -${props => props.desktopSize / 2}px;
    left: -${props => props.desktopSize / 2}px;

    /* outer circle */
    &:after {
      width: ${props => props.desktopSize * 2}px;
      height: ${props => props.desktopSize * 2}px;
      top: -${props => props.desktopSize / 2}px;
      left: -${props => props.desktopSize / 2}px;
    }
  }
`

/* Popover */
interface IOffsetProp {
  offset: string
  distanceFromThumb: number
  distanceFromTnumbDesktop?: number
}

export const PopoverPointerContainer = styled.div<IOffsetProp>`
  position: absolute;
  /* use offset to align the pointer to the thumb */
  transform: translate(${props => props.offset || '0px'}, -${props => props.distanceFromThumb}px);
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    transform: translate(
      ${props => props.offset || '0px'},
      -${props => props.distanceFromTnumbDesktop || props.distanceFromThumb}px
    );
  }
`

interface IPopoverPointerProps {
  size: string
}
export const PopoverPointer = styled.div<IPopoverPointerProps>`
  position: relative;
  background-color: ${props => props.theme.colors.blue};
  width: ${props => props.size || '1em'};
  height: ${props => props.size || '1em'};
  border-top-right-radius: 30%;
  transform: rotate(0deg) skewX(-30deg) scale(1, 0.866);

  :before,
  :after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: ${props => props.size || '1em'};
    height: ${props => props.size || '1em'};
    border-top-right-radius: 30%;
  }

  :before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  :after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`
interface IPopoverProps {
  width: number
  tabletWidth?: number
  desktopWidth?: number
  borderRadius?: number
  height: number
  tabletHeight?: number
  desktopHeight?: number
  distanceFromThumb: number
  distanceFromThumbTablet?: number
  distanceFromThumbDesktop?: number
}

export const Popover = styled.div<IPopoverProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => `${props.height}px` || 'auto'};
  top: -${props => props.distanceFromThumb}px;
  left: -${props => props.width / 2}px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.blue};
  border-radius: ${props => props.borderRadius || 24}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: ${props => props.tabletHeight || props.height}px;
    width: ${props => props.tabletWidth || props.width}px;
    left: -${props => (props.tabletWidth ? props.tabletWidth / 2 : props.width / 2)}px;
    top: -${props => props.distanceFromThumbTablet || props.distanceFromThumb}px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: ${props => props.desktopHeight || props.height}px;
    width: ${props => props.desktopWidth || props.width}px;
    left: -${props => (props.desktopWidth ? props.desktopWidth / 2 : props.width / 2)}px;
    top: -${props => props.distanceFromThumbDesktop || props.distanceFromThumb}px;
  }
`

interface IAlignItemsProps {
  alignItems?: 'center' | 'flex-start'
}
const PopoverFlexContainer = styled.div<IAlignItemsProps>`
  position: relative;
  padding: 8px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.alignItems || 'center'};
  z-index: 20;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    padding: 15px;
  }
`

export const PopoverValue = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: 24px;
    line-height: 33px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: 32px;
    line-height: 44px;
  }
`

const PopoverLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    font-size: 14px;
    line-height: 20px;
  }
`

interface IProps {
  max: number
  metric?: string
  popoverLabel?: string
  popoverValue?: string
  min: number
  name: string
  disabled?: boolean
  tickMarks?: string[]
  tickMarkColors?: string[]
  noVestibules?: boolean
  vestibuleWidth?: string
  skipDebounce?: boolean
  step: number
  stepsPerInterval?: number
  value: number
  marginTop?: number
  sliderPopover?: JSX.Element
  sliderThumb?: JSX.Element
  sliderLeftIcon?: JSX.Element
  sliderRightIcon?: JSX.Element
  removePopover?: boolean
  onChange(name: string, value: number): void
}

const SliderInput: FunctionComponent<IProps> = ({
  max,
  popoverLabel,
  popoverValue,
  min,
  name,
  disabled,
  tickMarks = priceTickMarks,
  tickMarkColors = defaultTickMarkColors,
  noVestibules,
  vestibuleWidth,
  skipDebounce,
  step,
  stepsPerInterval = STEPS_PER_INTERVAL,
  value,
  marginTop,
  sliderPopover,
  sliderThumb,
  sliderLeftIcon,
  sliderRightIcon,
  removePopover,
  onChange,
}) => {
  const theme = useContext(ThemeContext)

  const [initialized, setInitialized] = useState(false)
  const [newValue, setNewValue] = useState(value || 1)

  useEffect(() => {
    if (value || Number.isInteger(value)) setNewValue(value)
  }, [value])

  const debouncedValue = skipDebounce ? newValue : Number(useDebounce(newValue, 1000))

  useEffect(() => {
    if (!skipDebounce && initialized) {
      onChange(name, debouncedValue)
    }
  }, [debouncedValue])

  const handleChange = (values: number[]) => {
    if (disabled) return

    setInitialized(true)
    setNewValue(values[0])
    if (skipDebounce) onChange(name, values[0])
  }

  const handleValueClick = (idx: number) => () => handleChange([idx * stepsPerInterval])

  const handleIncrement = () => {
    const idx = Math.floor(value / stepsPerInterval)
    if (value >= max) return

    handleChange([(idx + 1) * stepsPerInterval])
  }

  const handleDecrement = () => {
    const idx = Math.floor(value / stepsPerInterval)
    if (value <= min) return

    handleChange([(idx - 1) * stepsPerInterval])
  }

  return (
    <SliderInputContainer marginTop={marginTop}>
      {sliderLeftIcon && (
        <>
          <SliderIconWrapper onClick={handleDecrement}>{sliderLeftIcon}</SliderIconWrapper>
          <Spacer
            size={20}
            responsiveSize={12}
          />
        </>
      )}
      <Range
        values={[newValue]}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <SliderFillContainer
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
          >
            {!noVestibules && (
              <SliderFillVestibule
                left
                maxValue={newValue >= max}
                color={theme.colors.blue}
                vestibuleWidth={vestibuleWidth}
              />
            )}
            <SliderFill
              ref={props.ref}
              background={getTrackBackground({
                values: [newValue],
                colors: [theme.colors.blue500, theme.colors.neutral100],
                min,
                max,
              })}
              rounded={noVestibules}
            >
              {children}
              <LineContainer>
                {tickMarks.map((tickValue, idx) => (
                  <RelativeContainer
                    key={`${tickValue + idx}`}
                    pointer
                  >
                    <MetricContainer onClick={handleValueClick(idx)}>
                      {tickValue
                        ? (
                          <>
                            <MetricIndicator
                              color={
                                idx * stepsPerInterval <= newValue
                                  ? tickMarkColors[idx]
                                  : theme.colors.neutral100
                              }
                            />
                            <MetricText>{`${addCommas(tickValue)}`}</MetricText>
                          </>
                        )
                        : null}
                    </MetricContainer>
                  </RelativeContainer>
                ))}
              </LineContainer>
            </SliderFill>
            {!noVestibules && (
              <SliderFillVestibule
                left={false}
                maxValue={newValue >= max}
                vestibuleWidth={vestibuleWidth}
                color={theme.colors.neutral100}
              />
            )}
          </SliderFillContainer>
        )}
        renderThumb={({ props }) => (
          <SliderThumbContainer
            {...props}
            style={{ ...props.style }}
          >
            {sliderThumb || (
              <SliderThumb
                size={24}
                desktopSize={30}
                aria-label="slider-thumb"
              />
            )}
            {!removePopover
              && (sliderPopover || (
                <>
                  <PopoverPointerContainer
                    offset="-4px"
                    distanceFromThumb={50}
                    distanceFromTnumbDesktop={55}
                  >
                    <PopoverPointer size="20px" />
                  </PopoverPointerContainer>
                  <Popover
                    width={144}
                    tabletWidth={192}
                    desktopWidth={251}
                    height={74}
                    tabletHeight={90}
                    desktopHeight={100}
                    distanceFromThumb={105}
                    distanceFromThumbTablet={122}
                    distanceFromThumbDesktop={132}
                  >
                    <PopoverFlexContainer>
                      {popoverLabel && <PopoverLabel>{popoverLabel}</PopoverLabel>}
                      <Spacer
                        size={10}
                        responsiveSize={5}
                      />
                      <PopoverValue>{popoverValue || value}</PopoverValue>
                    </PopoverFlexContainer>
                  </Popover>
                </>
              ))}
          </SliderThumbContainer>
        )}
      />
      {sliderRightIcon && (
        <>
          <Spacer
            size={20}
            responsiveSize={12}
          />
          <SliderIconWrapper onClick={handleIncrement}>{sliderRightIcon}</SliderIconWrapper>
        </>
      )}
    </SliderInputContainer>
  )
}

export default SliderInput
