import * as T from './types'

const PlusSquared = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.87499C10.5523 5.87499 11 6.32271 11 6.87499V9H12.7083C13.2606 9 13.7083 9.44772 13.7083 10C13.7083 10.5523 13.2606 11 12.7083 11H11V12.7083C11 13.2606 10.5523 13.7083 10 13.7083C9.44772 13.7083 9 13.2606 9 12.7083V11H6.87496C6.32268 11 5.87496 10.5523 5.87496 10C5.87496 9.44772 6.32268 9 6.87496 9H9V6.87499C9 6.32271 9.44772 5.87499 10 5.87499Z"
      fill="white"
    />
  </svg>
)

export default PlusSquared
