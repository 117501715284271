import * as T from './types'

const MinusSquared = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.875"
      width={props.width}
      height={props.height}
      rx="10"
      fill={props.fill}
    />
    <g clipPath="url(#clip0_460_120)">
      <path
        d="M12 16H21.3333"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_460_120">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(8.875 8)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default MinusSquared
